import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { setRedeemData } from "../redux/reducers/redeemSlice";
import { toast } from "react-toastify";

function ProductList() {
  const apiUrl = "https://cr-code.credencerewards.com/api/proxy/redeem-voucher";

  const list = useSelector((state) => state?.mobileNumber?.data);
  const code = useSelector((state) => state?.mobileNumber?.code);

  console.log("ddd", list);
  const [modalData, setModalData] = useState();
  const [isProcess, setIsProcess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log(modalData);
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email address"
      )
      .required("Email is Required"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, "Invalid mobile number")
      .required("Mobile number is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNumber: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });
  const handleSubmit = async (values) => {
    setIsProcess(true);
    let body = {
      credence_code: code,
      provider_id: modalData?.id,
      name: values?.name,
      email: values?.email,
      mobile_no: values?.mobileNumber,
    };
    try {
      // Make the POST request using Axios
      const response = await axios.post(apiUrl, body);

      // Handle the response data as needed
      if (response?.data?.status == 200) {
        let data = response?.data?.data;
        document.getElementById("modalClose").click();
        setShowModal(false);
        setIsProcess(false);
        dispatch(setRedeemData(data));
        navigate(`/congratulation`);
      } else {
        setShowModal(false);

        toast.error(response?.data?.message, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        document.getElementById("modalClose").click();
        setIsProcess(false);
      }

      // You can also perform other actions based on the response, such as updating the UI.
    } catch (error) {
      setIsProcess(false);
      setShowModal(false);

      // Handle any errors that occur during the request
      document.getElementById("modalClose").click();

      toast.error(error?.response?.data?.message, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.error("Error:", error);

      // You can display an error message to the user or perform other error-handling actions.
    }
  };

  const filteredProducts = list?.data?.filter((product) =>
    product?.product_name?.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const handleNaviagte = (data) => {
    setShowModal(true);
    setModalData(data);
  };
  return (
    <>
      <div className="main_content mt-3">
        {/* START SECTION BLOG */}
        <div className="section" style={{ backgroundColor: "#eaeaea" }}>
          <div className="container">
            <div className="row align-items-center mb-4 pb-1">
              <div className="col-12">
                <div className="product_header">
                  <div className="product_header_left">
                    <div className="widget">
                      <div className="search_form">
                        <form>
                          <input
                            required
                            name="q"
                            className="form-control"
                            placeholder="Search..."
                            type="text"
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <button
                            type="submit"
                            title="Search"
                            className="btn icon_search"
                          >
                            <i className="ion-ios-search-strong" />
                          </button>
                        </form>
                      </div>
                      {/* <a href="">Back to All Brands</a> */}
                    </div>
                  </div>
                  <div className="product_header_right">
                    Amount : <span className="price">₹ {list?.amount} </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              {filteredProducts && filteredProducts.length != 0 ? (
                <>
                  {filteredProducts?.map((item) => {
                    return (
                      <div className="col-lg-3 col-md-4 col-6">
                        <div
                          className="product"
                          style={{
                            boxShadow: "none",
                            backgroundColor: "#eaeaea",
                          }}
                        >
                          {/* {'{'}% if voucher.amount|brand_enabled:product.id == False %{'}'} */}
                          {/* <span className="pr_flash">Out of Stock</span> */}
                          <div
                            className="product_img"
                            style={{
                              width: "203px",
                              margin: "auto",
                              borderRadius: "20px",
                            }}
                          >
                            {item?.is_available == true ? (
                              <></>
                            ) : (
                              <span className="pr_flash">Out of Stock</span>
                            )}
                            <a>
                              <img
                                src={
                                  "https://cr-code.credencerewards.com" +
                                  item?.logo
                                }
                                className="col-12"
                                style={{
                                  width: "100% !important",
                                  border: "2px s",
                                }}
                                alt="{{ product.product_name }}-Image"
                              />
                            </a>
                            <div className="product_action_box">
                              <ul className="list_none ">
                                <li
                                  className=" btn btn-fill-out bg-white rounded-0"
                                  onClick={() => {
                                    item?.is_available
                                      ? handleNaviagte(item)
                                      : alert(
                                          "Brand is out of stock or not available this time, please select other brand."
                                        );
                                  }}
                                >
                                  <a
                                    data-toggle="modal"
                                    data-target="#select-brand-modal"
                                    // onclick="alert('Brand is out of stock or not available this time, please select other brand.')"
                                    className="prodcut-details"
                                  >
                                    Pick This Brand
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product_info">
                            <h6 className="product_title text-center">
                              <a
                                onclick="alert('Brand is out of stock or not available this time, please select other brand.')"
                                title="{{ product.product_name }}"
                              >
                                {item?.product_name}
                              </a>
                            </h6>
                            <div className="product_price text-center">
                              <small>
                                {/* <span className="price"> {item?.name} </span> */}
                                {/* <del>$99.00</del> */}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="row text-center align-items-center">
                  <div className="col-lg-12">
                    No Data Found <br />
                    <p className="col-lg-12 my-2 px-5 py-2 d-flex justify-content-between">
                      <a
                        href="https://api.whatsapp.com/send?phone=917977276921&text=Hello Credence Rewards admin, I Have a Business"
                        onclick="return confirm('Go to whatsapp')"
                      >
                        <i className="ion-social-whatsapp-outline" />
                        <span>Chat Directly</span>
                      </a>
                      <a href="mailto:support@credencerewards.com?subject=Issue related to order on Credence Rewards.com">
                        <i className="linearicons-envelope-open" />
                        <span>Write Us </span>
                      </a>
                    </p>
                  </div>
                </div>
              )}
            </div>
            {/* END SECTION BLOG */}
          </div>
        </div>
      </div>
      {showModal && (
        <>
          <div
            className="modal fade subscribe_popup "
            data-backdrop="static"
            data-keyboard="false"
            id="select-brand-modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="select-brand-modal"
            aria-hidden="true"
          >
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div
                className="modal-content"
                style={{ backgroundColor: "#f4f4f4", borderRadius: "15px" }}
              >
                <div className="modal-body">
                  <button
                    id="modalClose"
                    type="button"
                    className="close"
                    data-dismiss={isProcess ? "" : "modal"}
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <i className="ion-ios-close-empty" />
                    </span>
                  </button>
                  <div className="row g-0">
                    <div className="col-sm-5  p-5">
                      <img
                        className="background_bg product_img product_bg m-auto"
                        src={
                          "https://cr-code.credencerewards.com" +
                          modalData?.logo
                        }
                        // style={{
                        //   height: 183,
                        //   backgroundSize: "cover",
                        //   backgroundRepeat: "no-repeat",
                        // }}
                      />
                      <div className="product_info text-center ">
                        <h6 className="product_title">
                          {modalData?.product_name}
                        </h6>
                        <div className="product_price text-center py-2">
                          <small>
                            <span className="price" id="product_brand_name">
                              {modalData?.name}
                            </span>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-7">
                      <div className="popup_content">
                        <div className="popup-text">
                          <p>
                            Fill details to redeem your voucher.{" "}
                            <span style={{ float: "right" }}>
                              {" "}
                              Amount :{" "}
                              <span className="price">₹ {list?.amount}</span>
                            </span>
                          </p>
                        </div>
                        <form id="redeem-form" onSubmit={formik.handleSubmit}>
                          <div className="form-group mb-3">
                            <input
                              className="form-control rounded-0"
                              maxLength={50}
                              minLength={4}
                              name="name"
                              placeholder="Name"
                              required=""
                              id="name"
                              autoComplete="off"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <span className="text-danger">
                                {formik.errors.name}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group mb-3">
                            <input
                              type="hidden"
                              defaultValue=""
                              name="provider_id"
                              id="cr_provider_id"
                            />
                            <input
                              className="form-control"
                              maxLength={60}
                              name="email"
                              placeholder="Email"
                              id="email"
                              autoComplete="off"
                              type="text"
                              required=""
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <span className="text-danger">
                                {formik.errors.email}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group mb-3">
                            <input
                              type="hidden"
                              defaultValue="{{ voucher.credence_code }}"
                              name="credence_code"
                            />
                            <input
                              className="form-control"
                              maxLength={10}
                              minLength={10}
                              pattern="\d{10}"
                              name="mobileNumber"
                              required=""
                              placeholder="Mobile Number"
                              id="mobile_no"
                              type="text"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.mobileNumber}
                            />
                            {formik.touched.mobileNumber &&
                            formik.errors.mobileNumber ? (
                              <span className="text-danger">
                                {formik.errors.mobileNumber}
                              </span>
                            ) : null}
                          </div>
                          <div className="form-group mb-3">
                            {isProcess ? (
                              <button
                                className="btn btn-fill-line btn-block text-uppercase rounded-0 "
                                id="redeem-cr-code-btn"
                                title="Redeem"
                                type="button"
                                disabled
                              >
                                Processing...
                              </button>
                            ) : (
                              <button
                                className="btn btn-fill-line btn-block text-uppercase rounded-0 "
                                id="redeem-cr-code-btn"
                                title="Redeem"
                                type="submit"
                              >
                                Redeem
                              </button>
                            )}
                          </div>
                        </form>
                        <div className="chek-form">
                          <div className="custome-checkbox">
                            <p className="text-muted">
                              <small>
                                Disclaimer: Your Email and Mobile No. will be
                                used to share the brand voucher code.
                              </small>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4 border">
                      <div
                        id="accordion"
                        className="accordion accordion_style1 mx-4 my-2"
                      >
                        <div className="card">
                          <div className="card-header" id="headingOne">
                            <h6 className="mb-0">
                              {" "}
                              <a
                                className="collapsed"
                                data-bs-toggle="collapse"
                                href="#collapseOne"
                                aria-expanded="false"
                                aria-controls="collapseOne"
                              >
                                Description
                              </a>
                            </h6>
                          </div>
                          <div
                            id="collapseOne"
                            className="collapse"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body">
                              <p id="product-description">{modalData?.desc}</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingEight">
                            <h6 className="mb-0">
                              {" "}
                              <a
                                className="collapsed"
                                data-bs-toggle="collapse"
                                href="#collapseEight"
                                aria-expanded="false"
                                aria-controls="collapseEight"
                              >
                                Validity / Expiry
                              </a>{" "}
                            </h6>
                          </div>
                          <div
                            id="collapseEight"
                            className="collapse"
                            aria-labelledby="headingEight"
                            data-bs-parent="#accordion2"
                          >
                            <div className="card-body">
                              <p id="product-validity">{modalData?.expiry}</p>
                            </div>
                          </div>
                        </div>
                        <div className="card">
                          <div className="card-header" id="headingThree">
                            <h6 className="mb-0">
                              {" "}
                              <a
                                className="collapsed"
                                data-bs-toggle="collapse"
                                href="#collapseThree"
                                aria-expanded="false"
                                aria-controls="collapseThree"
                              >
                                Terms and Conditions
                              </a>
                            </h6>
                          </div>
                          <div
                            id="collapseThree"
                            className="collapse"
                            aria-labelledby="headingThree"
                            data-bs-parent="#accordion"
                          >
                            <div className="card-body">
                            

                              <small>
                                <p id="product-terms-conditions" dangerouslySetInnerHTML={{ __html: modalData?.t_c_content }} >
                                
                                </p>
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProductList;
