import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";
import AppRouter from "./AppRouter";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      {/* <div className="preloader">
  <div className="lds-ellipsis">
    <span />
    <span />
    <span />
  </div>
</div> */}
      <Router>
        <AppRouter />
      </Router>
    </>
  );
}

export default App;
