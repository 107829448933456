import axios from "axios";
import React, { useState } from "react";
import ProductList from "./ProductList";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setMobileNumber } from "../redux/reducers/mobileNumberSlice";
import detail from "../details.png";
import enter from "../enter.png";
import select from "../select.png";

function RedeemVocher() {
  const [code, setCode] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validation = () => {
    if (code == "") {
      toast.error("Please enter your credence code", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else if (code?.length !== 16) {
      toast.error("Please enter valid credence code", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    return true;
  };

  const handleAPICall = () => {
    // Replace with your actual code
    if (validation()) {
      setIsLoading(true);
      axios
        .get(
          `https://cr-code.credencerewards.com/api/proxy/check-credence-code/?credence_code=${code}`
        )
        .then((response) => {
          // Handle the API response here
          if (response?.data?.data) {
            navigate("/redeem-cr-code");
            dispatch(
              setMobileNumber({
                code: code,
                data: response?.data,
              })
            );
          } else {
            toast.error(response?.data?.message, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
          toast.error(error?.response?.data?.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // Handle any errors here
          console.error("API Error:", error);
        });
    }
    // Make the GET request using Axios
  };
  return (
    <>
      <div className="section pt-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading_s1 py-3 text-center">
                <h2>Enter Code to redeem your Voucher</h2>
              </div>
              <div className="field_form d-flex justify-content-center">
                <form
                  id="redeem_code-form"
                  className="col-md-12 col-lg-6 py-2"
                  noValidate="novalidate"
                >
                  <input
                    type="hidden"
                    name="csrfmiddlewaretoken"
                    defaultValue="RiGHDeXLuTBPPBfeVcQtHwATeI3Al9DVZ9sMDREbfMODGzEZM13HQCuyoNdrhfYi"
                  />
                  <div className="row d-flex justify-content-center">
                    <div className="form-group col-md-12 mb-3">
                      <input
                        className="form-control"
                        minLength={15}
                        maxLength={17}
                        name="credence_code"
                        defaultValue=""
                        placeholder="Enter Credence Code"
                        id="credence_code"
                        autoComplete="off"
                        type="text"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12 mb-3 d-flex justify-content-center">
                      <button
                        type="button"
                        title="Check Code!"
                        className="btn btn-fill-out"
                        id="submitButton"
                        name="submit"
                        onClick={() => handleAPICall()}
                      >
                        Redeem
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row g-1 pt-2">
            <p className="leads text-center">How to redeem ?</p>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <img src={enter} alt="" />
                </div>
                <div className="icon_box_content">
                  <h5>Enter Code</h5>
                  <p>Enter the voucher code &amp; click Redeem button.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <img src={select} alt="" />
                </div>
                <div className="icon_box_content">
                  <h5>Select Brand</h5>
                  <p>Select a brand available for your cr-code</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="icon_box icon_box_style1">
                <div className="icon">
                  <img src={detail} alt="" />
                </div>
                <div className="icon_box_content">
                  <h5>Enter Basic Details</h5>
                  <p>Enter Name, email, Mobile to get your selected voucher.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div className="preloader">
          <div className="lds-ellipsis">
            <span />
            <span />
            <span />
          </div>
        </div>
      )}
    </>
  );
}

export default RedeemVocher;
