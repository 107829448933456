import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom';

function Congratulation() {
    const location=useLocation()
const data=useSelector((state)=>state?.redeem)
 

function copycode(text) {
  try {
    navigator.clipboard.writeText(text);
    console.log('Text copied to clipboard');
  } catch (err) {
    console.error('Unable to copy text to clipboard', err);
  }
}


  return (
    <>
    <>
  <div className="section pt-3">
    <div className="container">
      <div className="row">
        <div className="col-lg-12  py-2">
          <div className="heading_s1 py-3 text-center">
            <h3>
              Congratulations! Your Credence Code has been redeemed
              Successfully.
            </h3>
          </div>
          <div className="row one-row-content d-flex justify-content-center">
            <div className="col-md-12 col-lg-6 col-sm-12 ">
              <div className="order_review pb-0">
                <div className="heading_s1 text-center">
                  <h5>Voucher details</h5>
                </div>
                <div className="table-responsive order_table">
                  <table className="table">
                    <tbody>
                      <tr className="p-2">
                        <td className="col-4">
                          <span className="product-qty">Code</span>
                        </td>
                        <td className="p-2 col-8 ">
                        {data?.voucher?.code}
                          <a
                            onClick={()=>copycode(data?.voucher?.code)}
                            title="Copy Code"
                            className=" p-2"
                          >
                            <i className="fa fa-copy sm" aria-hidden="true" />
                          </a>
                        </td>
                      </tr>
                      <tr className="p-2 ">
                        <td>
                          <span className="product-qty">Pin</span>
                        </td>
                        <td className="p-2 ">
                        {data?.voucher?.pin}
                          <a
                            onClick={()=>copycode(data?.voucher?.pin)}
                            
                            title="Copy Pin"
                            className="p-2"
                          >
                            <i className="fa fa-copy" aria-hidden="true" />
                          </a>
                        </td>
                      </tr>
                      {/* <tr className="p-2">
                        <td>
                          <span className="product-qty">Brand</span>
                        </td>
                        <td className="p-2 text-danger">
                        {brand}
                        </td>
                      </tr> */}
                      <tr className="p-2">
                        <td>
                          <span className="product-qty">Expiry</span>
                        </td>
                        <td className="p-2 ">
                       {data?.voucher?.expiry}
                        </td>
                      </tr>
                      <tr className="p-2">
                        <td>
                          <span className="product-qty">Email</span>
                        </td>
                        <td className="p-2 ">
                         {data?.email}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <p className="pb-0 mb-0 mt-3 pt-3">
                  Your Voucher will be sent to your Email and Mobile No.
                </p>
              </div>
            </div>
            <div className="col-lg-12 mt-5 text-center">
              <p className="text-muted">
                Thank you for choosing CredenceRewards.
                <br /> We look forward to serving you and making every occasion
                more special with the perfect gift of choice.
              </p>
              <Link to="/" className="btn btn-fill-out">
                Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="section border-top pb_70">
    <div className="container">
      <div className="row mt-3 mb-0 border p-2  bg-light">
        <h3 className="text-center">Voucher redeemed successfully :</h3>
        <div className="mx-4">
          <hr />
          <ol>
            <li>
              Hurry &amp; redeem your voucher before the expiry date. Kindly
              note this e-voucher code cannot be re-validated post expiry date..
            </li>
            <li>
              To know more about the redemption procedure click here, and click
              here for T&amp;Cs. For queries, please send an email on
              support@credencerewards.com
            </li>
            <li>
              E-Gift Cards are normally delivered instantly. But sometimes due
              to system issues, the delivery can be delayed up to 18 hours.
            </li>
          </ol>
          <hr />
          <p className="text-center">
            If you have questions or concerns about how your information is
            handled, please direct your inquiry to CredenceRewards.com via email
            at{" "}
            <a
              className="border-bottom border-danger"
              href="mailto:support@credencerewards.com"
            >
              support@credencerewards.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  </div>
</>

    </>
  )
}

export default Congratulation