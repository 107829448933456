// mobileNumberSlice.js
import { createSlice } from '@reduxjs/toolkit';

const redeemSlice = createSlice({
  name: 'redeemData',
  initialState: '', // Initial state should be an empty string or null
  reducers: {
    setRedeemData: (state, action) => {
      return action.payload;
    },

  },
});

export const { setRedeemData} = redeemSlice.actions;
export default redeemSlice.reducer;
