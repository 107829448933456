import React from "react";
import { Route, Routes } from "react-router";

import RedeemVocher from "./Components/RedeemVocher";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import ProductList from "./Components/ProductList";
import Congratulation from "./Components/Congratulation";

function AppRouter() {
  return (
    <>
      <Header/>
      <Routes>
        <Route path="/" element={<RedeemVocher/>} />
        <Route path="/redeem-cr-code" element={<ProductList/>} />

        <Route path="/congratulation" element={<Congratulation/>} />


      </Routes>
      
    </>
  );
}

export default AppRouter;
