// reducers/index.js
import { combineReducers } from 'redux';
import mobileNumberSlice from './mobileNumberSlice';
import redeemSlice from './redeemSlice';

const rootReducer = combineReducers({
    mobileNumber: mobileNumberSlice,
    redeem:redeemSlice
    // Add other reducers here
  });

export default rootReducer;
