import React from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <>
      <header className="header_wrap fixed-top header_with_topbar">
       
        <div className="bottom_header dark_skin main_menu_uppercase">
          <div className="container mt-2">
            <nav className="navbar navbar-expand-lg ">
              <Link className="navbar-brand pt-0" to="/">
                <img
                  className="logo_light"
                  src="../assets/images/logo_light.html"
                  alt="logo"
                  style={{ width: "200px" }}
                />
                <img
                  className="logo_dark"
                  src="../assets/Credence%20Rewards%20213_page-0001.jpg"
                  alt="logo"
                  style={{ width: "200px" }}
                />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-expanded="false"
              >
                <span className="ion-android-menu" />
              </button>
              <div
                className="collapse navbar-collapse justify-content-end"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav">
               
                  <li>
                    <a
                      className="nav-link nav_item "
                      href="https://credencerewards.com/"
                    >
                      Home
                    </a>
                  </li>
            
                
                 
                </ul>
              </div>
              {/* <ul className="navbar-nav attr-nav align-items-center">
                <li>
                  <a
                    href="javascript:void(0);"
                    className="nav-link search_trigger"
                  >
                    <i className="linearicons-magnifier" />
                  </a>
                  <div className="search_wrap">
                    <span className="close-search">
                      <i className="ion-ios-close-empty" />
                    </span>
                    <form
                      action="http://127.0.0.1:8000/brands/all-brands"
                      method="get"
                    >
                      <input
                        type="text"
                        placeholder="Search here . . . e.g amazon"
                        className="form-control"
                        id="search_input"
                        name="q"
                        aria-describedby="search"
                      />
                      <button type="submit" className="search_icon">
                        <i className="ion-ios-search-strong" />
                      </button>
                    </form>
                  </div>
                  <div className="search_overlay" />
                </li>
              </ul> */}
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
